import React, { FC, useContext, useState } from "react";
import { useModal } from "react-modal-hook";
import { useNavigate } from "react-router-dom";

import HamburgerIcon from "../../assets/icons/hamburger.svg";
import { Button } from "../common/button/Button";
import { WalletDropdown } from "./WalletDropdown";
import { WalletTab } from "../../modals/WalletModal";
import { useWalletModal } from "../../hooks/modals/useWalletModal";
import {
  AddFundsAndPlayModal,
  IFundOrWithdrawModalTabs,
} from "../modals/AddFundsAndPlayModal";
import {
  NetworkContext,
  AggregatedBalancesContext,
  WrappedWalletContext,
  NavContext,
} from "../../contexts";
import { FundsModal } from "../modals/FundsModal";
import { SolanaConnect } from "./WalletConnect";
import { twMerge } from "tailwind-merge";
import { DivvyContext } from "../../contexts/DivvyContext";

import DivvyLogo from "../../assets/logo/divvy_logo.png";
import BettingIcon from "../../assets/icons/divvy_betting.svg";
import { HouseContext } from "../../contexts/HouseContext";
import { DIVVY_BETTING_URL } from "../../constants/constants";

export const TopNav: FC<{}> = () => {
  const { loggedIn, walletPubkey, disconnect } =
    useContext(WrappedWalletContext);
  const { toggleLeftPanel } = useContext(NavContext);
  const { setWalletTab, showWalletModal } = useWalletModal();
  const { divvyUser, divvyPoints } = useContext(DivvyContext);
  const { houseToken } = useContext(HouseContext);

  const userPoint = divvyPoints.find(
    (point) => point.address === walletPubkey?.toString()
  );

  const totalPoints =
    Number(userPoint?.points || 0) +
    Number(userPoint?.lpPoints || 0) +
    Number(userPoint?.communityPoints || 0) +
    Number(userPoint?.referralPoints || 0);

  // const housepoolBalance = houseToken
  //   ? houseToken.availableTradingBalance / 10 ** houseToken.decimals
  //   : 0;

  const [walletModalSelectedTab, setWalletModalSelectedTab] =
    useState<IFundOrWithdrawModalTabs>(IFundOrWithdrawModalTabs.DEPOSIT);

  const { mergedTokens } = useContext(AggregatedBalancesContext);

  const [showFundsAndPlayModal, hideFundsAndPlayModal] = useModal(
    ({ in: open }) => (
      <AddFundsAndPlayModal
        showModal={showFundsAndPlayModal}
        visible={open}
        hideModal={() => {
          hideFundsAndPlayModal();
        }}
        walletModalSelectedTab={walletModalSelectedTab}
      />
    ),
    [mergedTokens, walletModalSelectedTab]
  );

  const [showFundsModal, hideFundsModal] = useModal(
    ({ in: open }) => (
      <FundsModal
        visible={open}
        hideModal={hideFundsModal}
        showFundsAndPlayModal={(selectedTab: IFundOrWithdrawModalTabs) => {
          setWalletModalSelectedTab(selectedTab);
          showFundsAndPlayModal();
        }}
      />
    ),
    []
  );

  const { chainDisplayName } = useContext(NetworkContext);

  return (
    <ul className="flex justify-between h-top-bar-height px-4 py-0 items-center z-40 shadow-black-regular backdrop-blur absolute bg-[#19213499] w-full">
      {/* HAMBURGER AND LOGO */}
      {/* <li className="text-white flex align-center">
        <div
          role="button"
          className="hidden sm:block h-6 w-6 my-auto"
          onClick={() => toggleLeftPanel()}
        >
          <HamburgerIcon />
        </div>
      </li> */}
      <div className="flex items-center">
        <img src={DivvyLogo} className="mr-10" />
        <div
          className="hidden md:flex items-center h-[40px] px-4 rounded-md cursor-pointer bg-white/10 hover:bg-white/20"
          onClick={() => window.open(DIVVY_BETTING_URL, "_blank")}
        >
          <BettingIcon className="mr-1" transform="scale(0.8 0.8)" />
          <span>Betting</span>
        </div>

        {/* <div className="hidden md:block bg-white/10 pt-3 pb-2 rounded-md px-3 mr-5 h-[40px]">
          <span>House Pool </span> <span className="mx-3">|</span>
          <span> {housepoolBalance.toFixed(2)}</span>
        </div> */}
      </div>
      <div className="flex items-center">
        {/* CONNECT, REGISTER, OR PROFILE */}
        {/* <li className="flex">
        {!loggedIn ? (
          <div className="flex space-x-2 sm:ps-2t">
            <SolanaConnect />
          </div>
        ) : (
          <Button onClick={disconnect}>Logout</Button>
        )}
      </li> */}
        <div className="hidden md:flex bg-white/10 items-center rounded-md px-3 mx-5 h-[40px]">
          <span>My Points</span> <span className="mx-3">|</span>
          <span> {totalPoints.toFixed(2)}</span>
        </div>
        {/* USERS TOKENS AND WALLET DROPDOWN */}
        <li>
          {loggedIn ? (
            <WalletDropdown
              openGasModal={() => {
                setWalletTab(WalletTab.GAS);
                showWalletModal();
              }}
              setWalletModalOpen={showFundsModal}
            />
          ) : (
            ""
          )}
        </li>
        <li className="hidden sm:flex ml-5">
          <div
            className={twMerge(
              "flex space-x-2 [&_.wallet-adapter-button]:rounded-md [&_.wallet-adapter-button]:h-[40px]",
              loggedIn
                ? "[&_.wallet-adapter-button]:bg-white/10"
                : "[&_.wallet-adapter-button]:bg-divvy-gradient"
            )}
          >
            <SolanaConnect user={divvyUser}></SolanaConnect>
          </div>
        </li>
      </div>
    </ul>
  );
};
