import { web3 } from "@coral-xyz/anchor";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";

import {
  GameSpecTokenStatus,
  GameStatus,
  HouseStatus,
  HouseTokenStatus
} from "./enums";
import { ENV_NAME } from "../utils/env/env";
import { defaultNetwork } from "../utils/chain/network";
import { IS_MAINNET } from "../utils/solana/rpc";
import { APP_NETWORK_TYPE } from "../types/chain";
import { getPlatformTokens } from "../utils/config/utils";

export const APPROXIMATE_MS_PER_SLOT = 400;

// DEVNET PROGRAMS
export const RANDOM_PROGRAM_PUBKEY_DEVNET = new PublicKey(
  "CxukdqqPpsxeXECDVGSK43YZ7RSeDDreHW4ZMwXKNHXv",
);

// MAINNET PROGRAMS
export const RANDOM_PROGRAM_PUBKEY_MAINNET = new PublicKey(
  "ZEEX2PvFMBN1bZX6RoqFdexTCjr4FiHGqtjMHBEx1E7",
);

// PROGRAMS
export const RANDOM_PROGRAM_PUBKEY =
  ENV_NAME == "MAINNET" ? RANDOM_PROGRAM_PUBKEY_MAINNET : RANDOM_PROGRAM_PUBKEY_DEVNET;

export const HERMES_ENDPOINT = "https://hermes.pyth.network";

// HOUSE
const HOUSE_PUBKEY_DEVNET_SOLANA = new PublicKey("4bR6E3tEWsnStRXbKzDhiBX4QJ7xxAyZi1xx6X9YfHAd");
const HOUSE_PUBKEY_MAINNET_SOLANA = new PublicKey("59E1zFmwwfqa3gitEon47BVTNB3A6WuN3fXWosPzBEDw");

// MB CONSTANTS
const HOUSE_PUBKEY_DEVNET_MB = new PublicKey("4bR6E3tEWsnStRXbKzDhiBX4QJ7xxAyZi1xx6X9YfHAd");
const HOUSE_PUBKEY_MAINNET_MB = new PublicKey("59E1zFmwwfqa3gitEon47BVTNB3A6WuN3fXWosPzBEDw");

export const getHousePubkey = (chain: APP_NETWORK_TYPE) => {
  switch (chain) {
    case APP_NETWORK_TYPE.SOLANA:
      return ENV_NAME == "MAINNET" ? HOUSE_PUBKEY_MAINNET_SOLANA : HOUSE_PUBKEY_DEVNET_SOLANA
    case APP_NETWORK_TYPE.MB_AND_SOLANA:
      return ENV_NAME == "MAINNET" ? HOUSE_PUBKEY_MAINNET_MB : HOUSE_PUBKEY_DEVNET_MB
    default:
      throw new Error(`Not a known chain config ${chain}`)
  }
}

// USDC MINT
const TOKEN_MINT_PUBKEY_DEVNET_SOLANA = new PublicKey(
  "7aHwUnXjTK38jeQaMJJkPPik889Ej2DbFhpa5aKxGCjU",
);
const TOKEN_MINT_PUBKEY_MAINNET_SOLANA = new PublicKey(
  "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
);

export const getTokenMint = (chain: APP_NETWORK_TYPE) => {
  const tokensForEnv = getPlatformTokens(defaultNetwork, chain)

  const token = tokensForEnv.find((token) => {
    return token.isBase == true
  })

  return token?.pubkey
}

const TOKEN_MINT_PUBKEY_DEVNET = TOKEN_MINT_PUBKEY_DEVNET_SOLANA
const TOKEN_MINT_PUBKEY_MAINNET = TOKEN_MINT_PUBKEY_MAINNET_SOLANA

export const TOKEN_MINT_PUBKEY =
  ENV_NAME == "MAINNET" ? TOKEN_MINT_PUBKEY_MAINNET : TOKEN_MINT_PUBKEY_DEVNET;

export const UNIX_DAY_IN_SECONDS = 86_400;

export const DEFAULT_CONFIRM_OPTIONS = {
  skipPreflight: true,
  commitment: "processed",
  preflightCommitment: "processed",
  // maxRetries: 10,
  // minContextSlot:
} as web3.ConfirmOptions;

export const HOUSE_STATUS_TAKING_BETS: HouseStatus[] = [
  HouseStatus.Active,
  HouseStatus.OutFlowsSuspended,
];
export const HOUSE_TOKEN_STATUS_TAKING_BETS: HouseTokenStatus[] = [
  HouseTokenStatus.Active,
  HouseTokenStatus.OutFlowsSuspended,
];
export const GAME_STATUS_TAKING_BETS: GameStatus[] = [GameStatus.Active, GameStatus.OutFlowsSuspended];
export const GAME_SPEC_TOKEN_STATUS_TAKING_BETS: GameSpecTokenStatus[] = [GameSpecTokenStatus.Active, GameSpecTokenStatus.OutFlowsSuspended];
// --- ADDITIONAL FROM POC ---

export const RPC_ENDPOINT_URL_MAGICBLOCK_DEVNET = "https://zeebit.magicblock.app";
export const RPC_ENDPOINT_WS_MAGICBLOCK_DEVNET = "wss://zeebit.magicblock.app:8900";
export const RPC_ENDPOINT_URL_MAGICBLOCK_MAINNET = "https://zeebit-mainnet.magicblock.app";
export const RPC_ENDPOINT_WS_MAGICBLOCK_MAINNET = "wss://zeebit-mainnet.magicblock.app:8900";

export const RPC_ENDPOINT_URL_MAGICBLOCK = IS_MAINNET ? RPC_ENDPOINT_URL_MAGICBLOCK_MAINNET : RPC_ENDPOINT_URL_MAGICBLOCK_DEVNET;
export const RPC_ENDPOINT_WS_MAGICBLOCK = IS_MAINNET ? RPC_ENDPOINT_WS_MAGICBLOCK_MAINNET : RPC_ENDPOINT_WS_MAGICBLOCK_DEVNET;

// DEVNET PROGRAMS
export const ZEEBIT_V2_PROGRAM_PUBKEY_DEVNET = new PublicKey('Gy2ugWYEPATy6kzEKbnDzauFjCivJe6ZdBjsLEfsJgQh');
export const ZEEBIT_V2_PROGRAM_PUBKEY_MAINNET = new PublicKey('zbtv2cgU1VzSBKNXZ96TcWSRVp1c8HxqCmRp8zPX1uh');

export const ZEEBIT_V2_PROGRAM = IS_MAINNET ? ZEEBIT_V2_PROGRAM_PUBKEY_MAINNET : ZEEBIT_V2_PROGRAM_PUBKEY_DEVNET;

export const LAMPORTS_FOR_INSTANCE_ACC = 0.004 * LAMPORTS_PER_SOL;
export const LAMPORTS_FOR_PLAYER_TOKEN_ACC = 0.00215064 * LAMPORTS_PER_SOL;
export const MIN_LAMPORTS_AUTO_SIGNER = 0.0005 * LAMPORTS_PER_SOL;
export const LAMPORT_TOPUP_AUTO_SIGNER = 0.001 * LAMPORTS_PER_SOL;
export const LAMPORTS_FOR_UPDATE_SLIP = 0.00174696 * LAMPORTS_PER_SOL;

export const LAMPORTS_FOR_AUTO_SIGNER_MB = LAMPORTS_FOR_UPDATE_SLIP + 1_000_000;
