import { QRCodeToDataURLOptions, toDataURL } from "qrcode";
import {
  useCallback,
  useMemo,
  useContext,
  useState,
  useEffect,
  Fragment,
} from "react";
import Button from "../../components/common/button/Button";
import FormItem from "../../components/common/form-item/FormItem";
import { BaseToast, BASE_TOAST_CONFIG } from "../../components/toast/BaseToast";
import { IMergedToken } from "../../contexts/AggregatedBalancesContext";
import { ToasterContext } from "../../contexts/ToasterContext";
import { useTokenAirdrop } from "../../hooks/useTokenAirdrop";
import { NetworkType, defaultNetwork } from "../../utils/chain/network";
import { Term } from "../registration/common/Term";
import { Dropdown } from "./Dropdown";
import { WarningMessage } from "./common/Warning";
import AirdropIcon from "./../../assets/icons/airdrop.svg";
import Input from "../../components/common/input/Input";
import CopyIcon from "./../../assets/icons/copy.svg";
import Icon from "../../components/common/icon/Icon";
import { Info } from "./common/Info";
import { TokenType } from "../../sdk/enums";
import { ZEEBIT_DOCS_URL } from "../../constants/constants";
import LinkIcon from "./../../assets/icons/link.svg";
import { WrappedWalletContext } from "../../contexts/WrappedWalletContext";
import { NetworkContext } from "../../contexts";

const QR_OPTIONS: QRCodeToDataURLOptions = {
  width: 120,
  margin: 2,
};

interface IFundTab {
  mergedTokens: IMergedToken[] | undefined;
  selectedToken: IMergedToken | undefined;
  setSelectedToken: Function;
  closeModal: (value: boolean) => void;
  setLoading: Function;
  loading: boolean;
}

export const FundTab = ({
  mergedTokens,
  selectedToken,
  setSelectedToken,
  closeModal,
  setLoading,
  loading,
}: IFundTab) => {
  const { chainDisplayName } = useContext(NetworkContext);
  const { handleAirdrop } = useTokenAirdrop();
  const handleTokenAirdrop = useCallback(() => {
    return handleAirdrop(
      selectedToken,
      () => {
        setLoading(true);
      },
      () => {
        setLoading(false);
      },
      () => {
        setLoading(false);
        closeModal(false);
      }
    );
  }, [handleAirdrop, selectedToken, closeModal, setLoading]);

  const isMainnet = useMemo(() => {
    return defaultNetwork == NetworkType.MAINNET;
  }, [defaultNetwork]);

  const airdrop = useMemo(() => {
    return (
      <div className="flex flex-col items-center gap-y-6 self-stretch">
        <div className="flex flex-col items-center gap-y-1">
          <div className="flex p-[10px] items-start gap-x-[10px]">
            <div className="w-[120px] h-[120px] text-gray-400">
              <AirdropIcon />
            </div>
          </div>
          <div className="text-lg text-center text-gray-400">Airdrop</div>
        </div>
        <div className="flex p-3 items-start content-start gap-x-1.5 self-stretch flex-wrap rounded-md border-2 border-solid border-gray-600 bg-gray-800 text-sm font-normal text-gray-300">
          Airdrop will fill your account with test currency you can use on the
          platform with no risk or exposure.
        </div>
        <Button
          isLoading={loading}
          className="self-stretch"
          variant={"divvy"}
          size={"lg"}
          onClick={handleTokenAirdrop}
        >
          Request airdrop
        </Button>
      </div>
    );
  }, [handleTokenAirdrop]);

  const toast = useContext(ToasterContext);
  const copyLinkToClipboard = useCallback(
    (toCopy: string) => {
      window.navigator.clipboard.writeText(toCopy);
      toast(
        <BaseToast
          icon={<Icon size="lg" icon={<LinkIcon />} />}
          type={"success"}
          message={"Successfully copied wallet pubkey to clipboard."}
        />,
        BASE_TOAST_CONFIG
      );
    },
    [history, toast]
  );

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const { walletPubkey } = useContext(WrappedWalletContext);

  const displayValue = useMemo(() => {
    if (selectedToken == null) {
      return "";
    }

    if (acceptedTerms == true) {
      return walletPubkey?.toString();
    } else {
      return "*******************************";
    }
  }, [acceptedTerms, selectedToken, walletPubkey]);

  const isWrappedAsset = useMemo(() => {
    return selectedToken?.house?.type == TokenType.OtherWrappedTradingCurrency;
  }, [selectedToken]);

  const wrappedWarning = useMemo(() => {
    if (isWrappedAsset == false) {
      return;
    }

    return {
      title: `CAUTION: Are you funding from a centralized exchange?`,
      message: `This is wrapped ${selectedToken?.context?.symbol} token and not a native ${selectedToken?.context?.symbol} accepted by most exchanges. Transferring this token to centralized exchanges will likely lead to the tokens being lost and irrecoverable. This token can be sent to other ${chainDisplayName} wallets or swapped for a different token supported by your centralized exchange. `,
      link: (
        <a
          className="hover:underline"
          target="__blank"
          href="https://spl.solana.com/token"
        >
          Learn more about wrapped tokens {`>`}
        </a>
      ),
    };
  }, [selectedToken, isWrappedAsset]);

  const [qrCode, setQrCode] = useState<string>();
  useEffect(() => {
    function getQrCode(wallet: string) {
      toDataURL(wallet, QR_OPTIONS, (err, url) => {
        if (err) {
          console.error(err);
          return;
        }

        setQrCode(url);
      });
    }

    if (walletPubkey == null) {
      return;
    }

    getQrCode(walletPubkey.toString());
  }, [walletPubkey]);

  const fund = useMemo(() => {
    return (
      <Fragment>
        {/* WARNING FOR WRAPPED ASSET */}
        {isWrappedAsset == true ? (
          <WarningMessage
            link={wrappedWarning?.link}
            title={wrappedWarning?.title}
            message={wrappedWarning?.message}
          />
        ) : (
          ""
        )}

        {/* Term */}
        <Term
          className="bg-gray-700 p-3 rounded-md"
          text={`I understand that transfers must be made on ${chainDisplayName} (SPL) network, and that tokens transferred to the wrong address or network will result in tokens being lost and irrecoverable.`}
          accepted={acceptedTerms}
          setAccepted={setAcceptedTerms}
        />

        <div className="flex flex-col items-center gap-y-6 self-stretch">
          {/* DISPLAY OF PUBKEY */}
          <div className="flex flex-col items-start gap-y-3 self-stretch">
            <div className="flex flex-col items-start gap-y-1 self-stretch">
              <FormItem
                className="self-stretch"
                label={`Your ${selectedToken?.context?.symbol} fund address`}
              >
                <Input
                  disabled={true}
                  name={selectedToken?.context?.name || "Unknown"}
                  type="string"
                  variant="regular"
                  classes={{
                    label: "font-sm font-normal",
                    wrapper: "self-stretch",
                  }}
                  placeholder={acceptedTerms == false ? displayValue : ""}
                  value={acceptedTerms == true ? displayValue : ""}
                  rightInfo={
                    <div
                      className={`pe-1  ${
                        acceptedTerms == false
                          ? "text-gray-400"
                          : "cursor-pointer "
                      }`}
                      onClick={() => {
                        if (acceptedTerms == true && displayValue != null) {
                          copyLinkToClipboard(displayValue);
                        }
                      }}
                    >
                      <Icon icon={<CopyIcon />} size="lg" />
                    </div>
                  }
                />
              </FormItem>
            </div>
          </div>

          {/* QR CODE */}
          {acceptedTerms == false || qrCode == undefined ? (
            <div className="w-[120px] h-[120px] bg-gray-700 rounded-md"></div>
          ) : (
            <img className="rounded-md" src={qrCode} />
          )}

          {/* WARNING MESSAGE */}
          <Info>
            <div className="">
              {`Zeebit.io does not charge any deposit or withdrawal fees or charges. Some exchanges may charge withdrawal fees. If you’re not familiar with web3 flows please read ‘how to’ explainers below.`}
            </div>
            <div>
              <div
                onClick={() => {
                  window.open(ZEEBIT_DOCS_URL);
                }}
                className="cursor-pointer flex px-[10px] py-1.5 justify-center items-center gap-[10px] rounded-sm border-solid border-[1px] border-gray-400"
              >
                How to Guides
              </div>
            </div>
          </Info>
        </div>
      </Fragment>
    );
  }, [
    selectedToken,
    acceptedTerms,
    walletPubkey,
    wrappedWarning,
    isWrappedAsset,
    qrCode,
  ]);

  return (
    <Fragment>
      <Dropdown
        tokens={mergedTokens || []}
        selectedToken={selectedToken}
        updateSelectedToken={setSelectedToken}
      />
      {isMainnet ? fund : airdrop}
    </Fragment>
  );
};
