import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { NetworkContext } from "./NetworkContext";
import { APP_NETWORK_TYPE } from "../types/chain";
import { Button, IconFont } from "../components/common";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { defaultNetwork, NetworkType } from "../utils/chain/network";

export interface ITopBannerContext {
  message: ReactNode;
}

export const TopBannerContext = createContext<ITopBannerContext>(
  {} as ITopBannerContext
);

interface Props {
  children: any;
}

export const IS_ZEEBIT_V2_ALPHA_CLOSED = "zeebit-v2-alpha-closed";

export const TopBannerProvider = ({ children }: Props) => {
  const [message, setMessage] = useState<null | ReactNode>(null);
  const { chain } = useContext(NetworkContext);
  const [isZeebitV2AlphaClosed, setIsZeebitV2AlphaClosed] = useLocalStorage(IS_ZEEBIT_V2_ALPHA_CLOSED, false);

  useEffect(() => {
    const showV2AlphaBanner = defaultNetwork == NetworkType.MAINNET && !isZeebitV2AlphaClosed
    if (showV2AlphaBanner) {
      setMessage(
        <>
          <div className="text-sm font-normal text-gray-50 uppercase">
            Zeebit has just relaunched it’s v2 protocol in alpha. Some caps on volume and wager sizes are in place.
          </div>
          <button
            className="text-xs font-normal absolute right-4"
            onClick={() => {
              setIsZeebitV2AlphaClosed(true);
            }}
          >
            <IconFont name="close_md" />
          </button>
        </>
      );
    }
    if (!showV2AlphaBanner) {
      setMessage(null);
    }
  }, [chain, defaultNetwork, isZeebitV2AlphaClosed]);

  return (
    <TopBannerContext.Provider
      value={useMemo(
        () => ({
          message: message,
        }),
        [message]
      )}
    >
      {children}
    </TopBannerContext.Provider>
  );
};
