import * as anchor from "@coral-xyz/anchor";
import { Program } from "@coral-xyz/anchor";
import { PublicKey, AccountMeta, TransactionError } from "@solana/web3.js";
import * as base58 from "bs58";
import { sha256 } from "js-sha256";

import {
  RandomnessAuthorityStatus,
  RandomnessCallerStatus,
  RandomnessProviderStatus,
} from "./enums";
import { listenForTransaction } from "./utils";

export default class RandomnessDispatcher {
  private _program: Program;
  private _dispatcherPubkey: PublicKey;
  private _state: any;
  private _eventParser: anchor.EventParser;

  constructor(randomnessProgram: anchor.Program, dispatcherPubkey: PublicKey) {
    this._program = randomnessProgram;
    this._eventParser = new anchor.EventParser(
      this.program.programId,
      new anchor.BorshCoder(this.program.idl),
    );
    this._dispatcherPubkey = dispatcherPubkey;
  }

  static async load(randomnessProgram: anchor.Program, dispatcherPubkey: PublicKey) {
    const dispatcher = new RandomnessDispatcher(randomnessProgram, dispatcherPubkey);
    await dispatcher.loadState();
    return dispatcher;
  }

  async loadState() {
    // console.log(this._dispatcherPubkey)
    const state = await this.program.account.dispatcher.fetchNullable(this._dispatcherPubkey);
    if (state) {
      this._state = state;
    } else {
      throw new Error(
        `A valid dispatcher account was not found at the pubkey provided: ${this._dispatcherPubkey}`,
      );
    }
    return;
  }

  static deriveDispatcherPubkey(programId: PublicKey): PublicKey {
    const [dispatcherPubkey, _] = PublicKey.findProgramAddressSync(
      [anchor.utils.bytes.utf8.encode("dispatcher")],
      programId,
    );
    return dispatcherPubkey;
  }

  get program() {
    return this._program;
  }

  get eventParser() {
    return this._eventParser;
  }

  get programId() {
    return this._program.programId;
  }

  get publicKey() {
    return this._dispatcherPubkey;
  }

  get connection() {
    return this._program.provider.connection;
  }

  get state() {
    return this._state;
  }

  get bump() {
    return this._state ? Number(this._state.bump) : null;
  }

  get requestNonce() {
    return this._state ? Number(this._state.requestNonce) : null;
  }

  get status() {
    return this._state ? Object.keys(this._state.status)[0] : null;
  }

  get closedRequests() {
    return this._state ? Number(this._state.closedRequests) : null;
  }

  get openRequests() {
    return this._state ? Number(this._state.openRequests) : null;
  }

  get reward() {
    return this._state ? Number(this._state.reward) : null;
  }

  get listCallers() {
    return this._state?.callers;
  }

  get listProviders() {
    return this._state?.providers;
  }

  get listAuthorities() {
    return this._state?.authorities;
  }

  // Load Request Account
  async fetchRequestAccount(requestPubkey: PublicKey) {
    return this.program.account.requestAccount.fetchNullable(requestPubkey);
  }

  // Load Request Audit
  async fetchRequestAudit(requestPubkey: PublicKey): Promise<void> {
    // Get the transaction history for this pubkey

    // Find the Request (and if complete) the Response Event Emits

    // Parse these and return them

    return;
  }

  // Reproof Request/Response if Verifiably Fair
  verifyRandomnessRequest(
    randomnessResult: Buffer, // 64 u8 values returned by Randomness program
    randomnessRequestEvent: Object,
    randomnessResponseEvent: Object,
  ) {
    // Construct the message to be signed
    // Perform a dalek_ed12259 verification
    //  that the message was signed by the provider
    return;
  }

  async respond(
    requestPubkey: PublicKey,
    rentReceiverPubkey: PublicKey,
    callbackProgramPubkey: PublicKey,
    responseData: Buffer, // [u8; 64]
    slotUsed: anchor.BN,
    blockhashUsed: Buffer,
    remainingAccounts: AccountMeta[],
    commitmentLevel: anchor.web3.Commitment = "processed",
    onSuccessfulSendCallback?: Function, // callbackFn(txnHash);
    onSuccessfulConfirmCallback?: Function, // callbackFn(txnHash);
    onErrorCallback?: Function, // callbackFn(err);
  ) {
    try {
      const tx = await this.program.methods
        .respond({
          data: responseData,
          slotUsed: slotUsed,
          blockhash: blockhashUsed,
        })
        .accounts({
          dispatcher: this.publicKey,
          request: requestPubkey,
          provider: this.program.provider.publicKey,
          rentReceiver: rentReceiverPubkey,
          callbackProgram: callbackProgramPubkey,
          systemProgram: anchor.web3.SystemProgram.programId,
        })
        .remainingAccounts(remainingAccounts)
        .rpc();

      if (onSuccessfulSendCallback) {
        onSuccessfulSendCallback(tx);
      }

      if (onSuccessfulConfirmCallback) {
        onSuccessfulConfirmCallback(tx);
      }

      listenForTransaction(
        this.program.provider.connection,
        tx,
        commitmentLevel,
        onSuccessfulConfirmCallback,
        onErrorCallback,
      );
    } catch (err) {
      if (onErrorCallback) {
        onErrorCallback(err);
      } else {
        console.error(err);
      }
    }
  }

  deriveRequestAccountDiscriminator() {
    return Buffer.from(sha256.digest("account:RequestAccount")).subarray(0, 8);
  }

  async getOpenRequestAccounts() {
    const disriminator = this.deriveRequestAccountDiscriminator();
    // console.log(`disriminator`, disriminator)
    const awaitingFlag = Buffer.from([1]);
    const openRequestAccountInfos = await this.program.provider.connection.getProgramAccounts(
      this.program.programId,
      {
        filters: [
          {
            memcmp: {
              offset: 0, // Anchor account discriminator for RequestAccount type
              bytes: base58.encode(disriminator),
            },
          },
          {
            memcmp: {
              offset: 137, // 8 (discriminator) + 32 (dispatcher) + 32 (calling program) + 33 (assigned provider)
              bytes: base58.encode(awaitingFlag), // base58 encoded string of RequestStatus.Awaiting
            },
          },
        ],
      },
    );

    const openRequestAccounts = [];
    openRequestAccountInfos.forEach((accountInfo) => {
      openRequestAccounts.push({
        pubkey: accountInfo.pubkey,
        state: this.program.coder.accounts.decode("RequestAccount", accountInfo.account.data),
      });
    });
    return openRequestAccounts;
  }

  async listenForLogs(
    callbackFunction: (logs: {
      err: TransactionError | null;
      logs: string[];
      signature: string;
  }, context: {
      slot: number;
  }) => void,
    commitmentLevel: anchor.web3.Commitment = "processed",
  ) {
    const websocketId = this.program.provider.connection.onLogs(
      this.publicKey,
      callbackFunction,
      commitmentLevel,
    );
  }
}